/*
 * @Author: xiangyang
 * @Date: 2023-04-12 22:45:38
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-14 02:39:48
 * @Description: user.js
 */
import login from '../../api/login'
import { setToken, getToken } from '@/utils/storage'
const user = {
    state: {
        token: getToken(),
        roles: [],
        permissions: [],
        user: []
    },
    getters: {

    },
    mutations: {
        SET_TOKEN: (state, token) => {
            state.token = token
            console.log(token)
        },
        SET_ROLES: (state, roles) => {
            state.roles = roles
        },
        SET_PERMISSIONS: (state, permissions) => {
            state.permissions = permissions
        },
        SET_USER: (state, user) => {
            state.user = user
        }
    },
    actions: {
         Login({ commit }, userInfo) {
            return new Promise((resolve, reject) => {
                login.login(userInfo).then(res => {
                    setToken(res.data.token)
                    commit('SET_TOKEN', res.data.token)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })    
        },
        GetInfo({commit}){
            return new Promise((resolve, reject) => {
                login.getInfo().then( res => {
                        commit('SET_ROLES', res.data.roles)
                        commit('SET_PERMISSIONS', res.data.permissions)
                        commit('SET_USER', res.data.user)
                    resolve(res)
                }).catch( error => {
                    reject(error)
                })
            })
        }
    }
}


export default user