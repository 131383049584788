/*
 * @Author: xiangyang
 * @Date: 2023-04-12 22:45:38
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-13 16:45:59
 * @Description: 
 */
import request from '@/utils/request'

export default {
    getCodeImg(){
        return request({
            url: '/captchaImage',
            method: 'get',
            headers: {
                notToken: true
            },
            timeout: 2000
        })
    },
    login(data){
        return request({
            url: '/login',
            method: 'post',
            headers: {
                notToken: true
            },
            data
        })
    },
    getInfo(){
        return request({
            url: '/getInfo',
            method: 'get'
        })
    }
}