<!--
 * @Author: xiangyang
 * @Date: 2022-12-13 19:31:28
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-13 16:11:09
 * @Description: 
-->
<template>
  <div class="home">
    <transition name="fade" mode="out-in">
    <router-view />
    </transition>
    <!-- 导航栏 -->
    <van-tabbar route> 
      <van-tabbar-item icon="wap-home-o" to="/home">首页</van-tabbar-item>
      <van-tabbar-item icon="friends-o" to="/my">{{$store.state.user?'我的':'未登录'}}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  methods: {
    active(){
      console.log('xx')
    }
  }
};
</script>

<style lang="scss" scoped>
.van-tabbar-item {
  background-color: #fff;
}
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
