/*
 * @Author: xiangyang
 * @Date: 2023-04-12 22:45:38
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-13 20:38:41
 * @Description:
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/IndexView.vue'

Vue.use(VueRouter)

const routes = [
  // 登录模块
  {
    path: '/login',
    name: 'index',
    component: () => import('../views/login/IndexView.vue'),
    redirect: '/login/username',
    children: [
      {
        name: 'form',
        path: 'username',
        component: () => import('../views/login/loginView.vue')
      },
      {
        path: 'retrieve',
        component: () => import('../views/login/RetrieveView.vue')
      }
    ]
  },
  {
    path: '/',
    name: 'homes',
    component: HomeView,
    redirect: '/home',
    children: [
      {
        path: 'my',
        name: 'my',
        component: () => import('../views/my/MyView.vue')
      },
      {
        path: '/home',
        // name: 'Home',
        component: () => import('../views/Home/HomeView.vue')
      },
      {
        path: 'detail',
        name: 'detail',
        component: () => import('../views/Home/DetailsView.vue')
      }
    ]
  },
  {
    path: '/my/personal',
    component: () => import('../views/user-profile/PersonalView.vue')
  },
  {
    path: '/qunpin/:id',
    component: () => import('../views/assess/qunzhong/qunView.vue'),
  },
  {
    name:'massesView',
    path: '/massesView',
    component: () => import('../views/assess/qunzhong/massesView.vue'),
  },
  {
    name:'member',
    path: '/member',
    component: () => import('../views/assess/qunzhong/memberView.vue'),
  },
  {
    name:'assess',
    path: '/assess',
    component: () => import('../views/assess/qunzhong/assessView.vue'),
  },
  {
    name:'Other',
    path: '/Other',
    component: () => import('../views/assess/eachOther/massView.vue'),
  },
  {
    name:'submitOther',
    path: '/submitOther',
    component: () => import('../views/assess/eachOther/submitOther.vue'),
  },
  {
    name:'submitSelf',
    path: '/submitSelf',
    component: () => import('../views/assess/self/submitSelf.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode:"history"
})

export default router
