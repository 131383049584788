/*
 * @Author: xiangyang
 * @Date: 2023-04-12 22:45:38
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-13 00:44:44
 * @Description: 
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import 'amfe-flexible'
import './assets/iconfont/iconfont.css'
import infiniteScroll from 'vue-infinite-scroll'
import '../permission'
Vue.config.productionTip = false
Vue.use(infiniteScroll)

Vue.use(Vant)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
