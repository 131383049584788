/*
 * @Author: xiangyang
 * @Date: 2023-04-13 00:38:14
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-13 16:32:52
 * @Description: 
 */
import router from './src/router/index'
import store from '@/store/index'
import {getToken} from '@/utils/storage'
const whileList = ['/login', '/login/username', '/home', '/my', '/massesView', '/qunpin/2' ,'/member', '/assess', '/detail']
router.beforeEach((to, from, next) => {
    if(getToken()){
        store.dispatch('GetInfo').then((res) => {
            console.log('xxxxx', res)
        })
        next()
    }else{
        if(whileList.indexOf(to.path) !==-1){
            next()
        }else{
            next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
        }
    }
})