/*
 * @Author: xiangyang
 * @Date: 2023-04-12 22:45:38
 * @LastEditors: xiangyang
 * @LastEditTime: 2023-04-14 03:11:03
 * @Description: 
 */
import axios from 'axios'
import { Toast,Dialog } from 'vant'
import { getToken,removeToken } from '@/utils/storage'
import Vuex from 'vuex'
import router from '../router/index'
const service = axios.create({
    baseURL: 'http://test.cuihua.top/api',
    timeout: 10000
})


let loading       
function startLoading() {  
    loading = Toast.loading({
        message: '加载中...',
        forbidClick: true
    })
}

function endLoading() {   
    loading.clear()
}

service.interceptors.request.use(config => {
    const notToken = (config.headers || {}).notToken === true
    if(getToken() && !notToken){
        // eslint-disable-next-line dot-notation
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    startLoading();
    return config
})

service.interceptors.response.use(res => {
    const code = res.data.code
    const msg = res.data.msg
    if(code == 401){
        removeToken()
        Dialog.confirm({
            title: '账号已过期',
            message: '是否重新登陆',
          })
            .then(() => {
                router.push({path: '/login'})
            })
            .catch(() => {
              // on cancel
        });
        Toast.fail('无效对话重新登陆');
        console.log(res)
        // return Promise.reject(new Error('无效对话重新登陆'))
    }else if(code===500){
       Toast.fail(msg);
        return Promise.reject(new Error(msg))
    }else if(code!==200){
        return Promise.reject(new Error('err'))
    }else{
        // Toast.success(msg);
        endLoading();
        return res.data
    }

})

export default service